export function track(name, options = {}) {
  this.$gtag.event(name, options)
  // this.$gtm.trackEvent({
  //   event: null, // Event type [default = 'interaction'] (Optional)
  //   category: options.event_category,
  //   action: options.event_category,
  //   label: name,
  //   value: options.value,
  // });
}
