import API from '../../api'

export default {
  inject: ['moment', 'Precision'],
  name: 'BetRecord',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isDataLoading: false,
      isProviderLoading: false,
      isGameTypeLoading: false,
      result: {
        itemObject: null
      },
      search: {
        params: {
          ProviderID: -1,
          GameTypeID: -1,
          PageIndex: 1,
          PageSize: 30
        }
      },
      ProviderList: [],
      GameTypeList: [],
      table: {
        Data: [],
        headers: [
          {
            label: 'ProviderName',
            locale: this.$t('BettingRecord.Fields.ProviderName')
          },
          {
            label: 'GameType',
            locale: this.$t('BettingRecord.Fields.GameType')
          },
          {
            label: 'Turnover',
            locale: this.$t('BettingRecord.Fields.Turnover')
          },
          {
            label: 'Netwin',
            locale: this.$t('BettingRecord.Fields.Netwin')
          },
          {
            label: 'BetTime',
            locale: this.$t('BettingRecord.Fields.BetTime')
          }
        ],
        columns: [
          {
            key: 'ProviderName',
            value: null
          },
          {
            key: 'GameType',
            value: null
          },
          {
            key: 'Turnover',
            value: null
          },
          {
            key: 'Netwin',
            value: null
          },
          {
            key: 'BetTime',
            value: null
          }
        ],
        TotalCount: 0
      }
    }
  },
  mounted() {
    this.getProviderList()
    this.getGameTypeList(-1)
  },
  computed: {
    ProviderOptions() {
      return [
        { value: -1, label: this.$t('Shared.All') },
        ...this.ProviderList.map(lang => ({
          value: lang.ProviderID,
          label: lang.ProviderName
        }))
      ]
    },
    GameTypeOptions() {
      return [
        { value: -1, label: this.$t('Shared.All') },
        ...this.GameTypeList.map(lang => ({
          value: lang.GameTypeIDs,
          label: this.$t(`GameType.${lang.GameTypeName}`)
        }))
      ]
    }
  },
  methods: {
    close() {
      if (this.isDataLoading || this.isProviderLoading || this.isGameTypeLoading) return
      this.$emit('closeDialog', this.dialogName, this.result)
      this.$emit('update:show', false)
    },
    clearTable() {
      this.table.Data = []
      this.table.headers = []
      this.table.columns = []
    },
    setTable(headers, fields) {
      this.table.headers = headers
      this.table.columns = fields
    },
    formatter(row, column, cellValue, index) {
      switch (column.key) {
        case 'BetTime':
          if (cellValue !== null) {
            cellValue = this.moment(cellValue).format('YYYY-MM-DD HH:mm:ss')
          }
          break
        case 'Turnover':
        case 'Netwin':
          if (cellValue !== null) {
            cellValue = this.$options.filters.toThousandFilter(this.Precision.divide(cellValue, this.$store.getters.CurrencyRate))
          }
          break
        case 'GameType':
          if (cellValue) {
            cellValue = this.$t(`GameType.${cellValue}`)
          }
          break
        default:
          break
      }
      return cellValue
    },
    pagination(data) {
      if (this.isDataLoading) return
      this.search.params.PageIndex = data.page
      this.search.params.PageSize = data.limit
      this.$nextTick(async () => {
        await this.getGameBetRecord()
      })
    },
    async getGameBetRecord() {
      var formData = {
        ProviderID: this.search.params.ProviderID,
        GameTypeIDs: this.search.params.GameTypeID === -1 ? null : this.search.params.GameTypeID,
        PageIndex: this.search.params.PageIndex,
        PageSize: this.search.params.PageSize
      }
      this.isDataLoading = true
      this.$nextTick(async () => {
        const result = await API.Game.Get_GameBetRecord(formData)
        if (result.ErrorCode === 0 && result.Data) {
          this.table.Data = result.Data
          this.table.TotalCount = result.Pagination.TotalCount
        }
        this.isDataLoading = false
      })
    },
    async getProviderList() {
      const result = await API.Game.Get_GameProviderList({ GameTypeID: null })
      if (result.ErrorCode === 0 && result.Data) {
        this.ProviderList = result.Data
      }
    },
    async getGameTypeList(ProviderID) {
      var formData = {
        GameProviderID: ProviderID
      }
      this.isGameTypeLoading = true
      const result = await API.Game.Get_GameTypeList(formData)
      this.isGameTypeLoading = false
      if (result.ErrorCode === 0 && result.Data) {
        this.GameTypeList = result.Data
      }
    },
    async onProviderSelectChange() {
      this.search.params.GameTypeID = -1
      await this.getGameTypeList(this.search.params.ProviderID)
    },
    submit() {
      if (this.isDataLoading) return
      this.search.params.PageIndex = 1
      this.getGameBetRecord()
    }
  }
}
