import { validateContainLeastNumberAndLetter } from '../../../validate'
import i18n from '@/language'

const NewPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
}

export {
  NewPassword
}
