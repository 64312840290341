import API from '../../api'
import imageCompression from 'browser-image-compression'
import { validator } from 'agmp-utility'

export default {
  inject: ['moment'],
  name: 'DetailInfo',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      form: {
        Name: null,
        Birthday: '0000-00-00',
        // IdNumber: null,
        PhoneNumber: null,
        OTP: null,
        BankID: null,
        BankAccount: null,
        BankImage: null,
        Email: null
      },
      BankList: [],
      accept: 'image/jpeg,image/png',
      image: null,
      lastTime: 180,
      isPhoneNumberLock: false,
      isFirstClick: false,
      myInterval: null
    }
  },
  computed: {
    AgentMode() {
      return {
        AgentMode: 1,
        IsBind: false,
        ...this.$store.state.user.IsBind.data
      }
    }
  },
  mounted() {
    const memberInfo = this.$store.getters.MemberInfo
    if (!memberInfo?.PhoneNumber) {
      this.$message.error({
        message: this.$t('Message.Msg.PleaseEnterCellPhone')
      })
      this.close()
    }
    this.form.Name = this.itemObject.Name
    this.form.Birthday = this.itemObject.Birth
    // this.form.IdNumber = this.itemObject.IdNumber
    // 計時器是否為第一次點擊
    this.isFirstClick = this.$store.getters.TimerFirstClick
    // 鎖住phone欄位
    this.isPhoneNumberLock = this.$store.getters.TimerLock
    // 暫存phone
    var tempPhone = this.$store.getters.TimerTempData ? this.$store.getters.TimerTempData.phoneNumber : ''
    this.form.PhoneNumber = this.itemObject.PhoneNumber || tempPhone
    this.form.Email = this.itemObject.Email
    if (this.$store.getters.TimerCount > 0 && this.$store.getters.TimerLock) {
      this.InitTimer(this.$store.getters.TimerCount)
    }
    this.$nextTick(async () => {
      await this.BindCheck()
    })
    if (this.itemObject && this.itemObject.Type === 3) {
      this.getMemberBank()
    }
  },
  beforeDestroy() {
    this.ClearTimer()
  },
  methods: {
    close() {
      this.$emit('closeDialog', this.dialogName, this.result)
      this.$emit('update:show', false)
    },
    async BindCheck() {
      this.$store.dispatch('user/getIsBind')
    },
    getMemberBank() {
      // 審核中在用的
      var formData = {
        Action: 'Member'
      }
      API.Bank.getList(formData).then(res => {
        const { Data } = res
        if (Data && Array.isArray(Data.BankList) && Data.BankList.length > 0) {
          this.form.BankAccount = Data.BankList[0].MemberBankAccount
          this.form.BankID = Data.BankList[0].MemberBankID
          // this.image = Data.BankList[0].MemberBankIcon
          this.image = Data.BankList[0].MemberBankImage // type === 3 前台需看到送審的那張銀行圖片
        }
      }).catch(() => {
        console.log('error')
      })
    },
    async getOTP() {
      if (!this.form.PhoneNumber) {
        this.$message.error({
          message: this.$t('Message.Msg.PleaseEnterCellPhone')
        })
        return false
      }

      if (!validator.validPhone(this.$store.getters.LocationCode, this.form.PhoneNumber)) {
        this.$message.error({
          message: this.$t('Shared.PhoneNumber') + this.$t('Shared.Format') + ' ' + this.$t('Shared.Error')
        })
        return false
      }

      var formData = {
        CellPhoneNumber: this.form.PhoneNumber
      }
      this.$store.commit('app/SET_ISLOADING', true)
      const { ErrorCode, Data } = await API.User.BindPhone(formData)
      this.$store.commit('app/SET_ISLOADING', false)
      if (ErrorCode === 0 && Data) {
        this.$message.success({
          message: this.$t('Get OTP..., please wait for message')
        })
        await this.$store.dispatch('timer/setFirstClick', true)
        await this.$store.dispatch('timer/setTimerLock', true)
        await this.$store.dispatch('timer/setTimerTempData', {
          phoneNumber: this.form.PhoneNumber
        })
        this.InitTimer()
      }
    },
    submit() {
      this.$refs.form.validate().then(async valid => {
        if (valid) {
          // var isotp =
          var formData = {
            IsOTP: false,
            BankID: this.form.BankID,
            BankAccount: this.form.BankAccount,
            BankImg: this.form.BankImage,
            VerificationCode: this.form.OTP,
            // IDCardNo: this.form.IdNumber,
            Name: this.form.Name,
            Birthday: this.form.Birthday,
            Email: this.form.Email
          }
          this.$store.commit('app/SET_ISLOADING', true)
          const { ErrorCode, Data } = await API.User.BindMember(formData)
          this.$store.commit('app/SET_ISLOADING', false)
          if (ErrorCode === 0 && Data) {
            this.$message.success({
              message: this.$t('Shared.Success')
            })
            this.$track('綁定會員資料成功', {
              event_category: 'BindInfo',
              event_label: '綁定會員資料成功'
            })
            this.$emit('updateInfo', true)
            this.ClearTimer()
            this.$store.dispatch('timer/setTimerLock', false)
            this.$store.dispatch('timer/setFirstClick', false)
            this.close()
          }
        } else {
          console.log('error')
          return false
        }
      })
    },
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files
      if (!files.length) {
        return
      }
      const isIMAGE = (files[0].type === 'image/jpeg' || files[0].type === 'image/png')
      const isLt1M = files[0].size / 1024 / 1024 < 0.8
      // console.log(files[0].size)
      // const isLt1M = files[0].size / 1024 <= 8000

      if (!isIMAGE) {
        this.$message.error('Only Upload jpg/png !')
        e.target.files = null
        return
      }
      if (!isLt1M) {
        this.compressImage(files[0])
      } else {
        this.createImage(files[0])
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = this.openFile
      }
    },
    compressImage(file) {
      const self = this
      const options = {
        maxSizeMB: 0.7,
        initialQuality: 0.6
      }
      this.$store.commit('app/SET_ISLOADING', true)
      imageCompression(file, options)
        .then(res => {
          console.log(res)
          this.$store.commit('app/SET_ISLOADING', false)
          const reader = new FileReader()
          reader.readAsDataURL(res)
          reader.onloadend = function() {
            const base64data = reader.result
            self.form.BankImage = base64data
            self.image = base64data
          }
        })
        .catch(err => {
          console.log(err)
          this.$store.commit('app/SET_ISLOADING', false)
        })
    },
    openFile(event) {
      this.form.BankImage = event.target.result
      // this.$emit('getFile', event.target.result, this.formIndex)
    },
    createImage(file) {
      // var image = new Image()
      var reader = new FileReader()
      var vm = this

      reader.onload = (e) => {
        vm.image = e.target.result
      }
      reader.readAsDataURL(file)
    },
    InitTimer(reloadTime = 180) {
      if (this.myInterval) {
        clearInterval(this.myInterval)
      }
      this.myInterval = setInterval(() => {
        reloadTime--
        this.$store.dispatch('timer/setTimerCount', reloadTime)
        this.$store.dispatch('timer/setTimerLock', true)
        if (reloadTime === 0) {
          reloadTime = 180
          this.$store.dispatch('timer/setTimerLock', false)
          this.$store.dispatch('timer/setFirstClick', false)
          clearInterval(this.myInterval)
        }
      }, 1000)
    },
    ClearTimer() {
      if (this.myInterval) {
        this.lastTime = 180
        clearInterval(this.myInterval)
      }
    }
  }
}
