import { validateEmoji } from '../../../validate'
import i18n from '@/language'

const emoji = {
  validate(value, {}) {
    return validateEmoji(value)
  },
  params: [],
  message: () => i18n.t('Not Support Emoji')
}

const strlength = {
  validate(value, { min, max }) {
    console.log(min, max)
    return value.length >= min && value.length <= max
  },
  params: ['min', 'max'],
  message: (fieldName, { min, max, _value_ }) => {
    if (Number(min) === 0) {
      min = 1
    }
    if (Number(max) === 0) {
      max = 9999999
    }

    if (Number(min) !== 0) {
      if (Number(_value_) < Number(min)) {
        return i18n.t('Message.Msg.AmountLessThen', { Amount: min })
      }
    }
    if (Number(max) !== 0) {
      if (Number(_value_) > Number(max)) {
        return i18n.t('Message.Msg.AmountGreaterThen', { Amount: max })
      }
    }
  }
}

const required = {
  validate(value, {}) {
    try {
      value = value.toString()
      return !!value
    } catch (e) {
      return false
    }
  },
  params: [],
  message: () => i18n.t('Shared.Required')
}

const IntegerOnly = {
  validate(value, {}) {
    return /^[0-9]*$/g.test(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

export {
  emoji,
  strlength,
  required,
  IntegerOnly
}
