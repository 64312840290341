import { validateEmoji } from '../../../validate'
import i18n from '@/language'
import _ from 'lodash'

const DepositBankAccount = {
  validate(value, {}) {
    return true
  },
  params: [],
  message: ''
}

const DepositMemo = {
  validate(value, {}) {
    return validateEmoji(value)
  },
  params: [],
  message: ''
}

const Deposit_Amount = {
  validate(value, { min, max }) {
    if (!/^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/g.test(value)) return false
    if (Number(min) === 0) {
      min = 1
    }
    if (Number(max) === 0) {
      max = 9999999
    }
    var reg = Number(value) >= min && Number(value) <= max

    return reg
  },
  params: ['min', 'max'],
  message: (fieldName, { min, max, _value_ }) => {
    if (!/^[+-]?(?=.)(?:\d+,)*\d*(?:\.\d+)?$/g.test(_value_)) return i18n.t('Shared.Amount') + i18n.t('Shared.Error')
    if (Number(min) === 0) {
      min = 1
    }
    if (Number(max) === 0) {
      max = 9999999
    }

    if (Number(min) !== 0) {
      if (Number(_value_) < Number(min)) {
        return i18n.t('Message.Msg.AmountLessThen', { Amount: min })
      }
    }
    if (Number(max) !== 0) {
      if (Number(_value_) > Number(max)) {
        return i18n.t('Message.Msg.AmountGreaterThen', { Amount: max })
      }
    }
  }
}

export {
  DepositBankAccount,
  DepositMemo,
  Deposit_Amount
}
