import Decimal from 'decimal.js'

function add(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a)
    return result.plus(b).toNumber()
  } else {
    return 0
  }
}

function subtract(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a)
    return result.minus(b).toNumber()
  } else {
    return 0
  }
}

function multiply(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a)
    return result.times(b).toNumber()
  } else {
    return 0
  }
}

function divide(a, b) {
  if (checkNullUndefined(a) && checkNullUndefined(b)) {
    const result = new Decimal(a)
    return result.div(b).toNumber()
  } else {
    return 0
  }
}

function checkNullUndefined(val) {
  return (val !== undefined && val !== '' && val !== null && isNumber(val))
}

function isNumber(val) {
  return !Number.isNaN(Number(val))
}

var decimalObj = {
  add,
  subtract,
  multiply,
  divide
}
export default decimalObj
