import axios from 'axios'

import store from '@/store'

import router from '@/router'

import { Message, MessageBox } from 'element-ui'

import i18n from '@/language'

const map = []

const service = axios.get(`${process.env.VUE_APP_SPOT}/domains/url`, { params: { version: process.env.VUE_APP_KEYWORD, env: process.env.VUE_APP_SOCKET_ENV }})
  .then(res => {
    if (res.data?.Api) {
      window.domains = res.data
      store.dispatch('app/setDomains', res.data)
      const instanse = axios.create({
        baseURL: res.data.Api,
        timeout: 3 * 1000 * 60
      })
      instanse.interceptors.request.use(
        async config => {
          config.headers['Content-Type'] = 'application/json'
          config.headers.source = process.env.VUE_APP_KEYWORD
          config.headers.platform = 'OCMS'
          config.headers.device = store.getters.Device
          config.headers['accept-language'] = store.getters.language
          config.headers['agmp-lang'] = store.getters.language || 'en'
          config.headers.ac = store.getters.AgentCode
          config.headers.tn = store.getters.Token
          config.headers.version = store.getters.Version
          return config
        },
        error => {
          console.log('error=====>', error)
          return Promise.reject(error)
        }
      )
      instanse.interceptors.response.use(
        async response => {
          if (response.status === 200) {
            // 統一回傳 api 發生錯誤的自定義response Data
            if (response.data.ErrorCode !== 0) {
              const urlSearchParams = new URLSearchParams(window.location.search)
              const params = Object.fromEntries(urlSearchParams.entries())
              switch (response.data.ErrorCode) {
                case 900012:
                  console.log('系統維護')
                  await store.dispatch('app/setIsMaintainence', true)
                  await store.dispatch('app/setMaintainContent', response.data.Data?.MaintainContent)
                  router.push({ path: '/', query: params })
                  break
                case 900014:
                case 900015:
                case 900016:
                case 900017:
                case 900018:
                  if (!store.getters.isAlertShow) {
                    await store.dispatch('app/setIsAlertShow', true)
                    MessageBox.alert(response.data.ErrorMsg, {
                      confirmButtonText: 'Confirm',
                      callback: async () => {
                        await store.dispatch('user/logout')
                        await store.dispatch('app/setIsAlertShow', false)
                        router.push('/')
                      }
                    })
                  }
                  break
                case 900019: // Token 過期
                  console.log('Token 過期')
                  if (!store.getters.isAlertShow) {
                    await store.dispatch('app/setIsAlertShow', true)
                    MessageBox.alert(i18n.t('Message.Msg.TokenExpiredMsg'), i18n.t('Message.Msg.TokenExpired'), {
                      confirmButtonText: i18n.t('Shared.Confirm'),
                      callback: async () => {
                        await store.dispatch('user/logout')
                        await store.dispatch('app/setIsAlertShow', false)
                        router.push('/')
                      }
                    })
                  }
                  break
                case 900047: // 多平台登入
                  console.log('多平台登入')
                  if (!store.getters.isAlertShow) {
                    await store.dispatch('app/setIsAlertShow', true)
                    MessageBox.alert(i18n.t('Message.Msg.RepeatLoginMsg'), i18n.t('Message.Msg.RepeatLogin'), {
                      confirmButtonText: i18n.t('Shared.Confirm'),
                      callback: async () => {
                        await store.dispatch('user/logout')
                        await store.dispatch('app/setIsAlertShow', false)
                        router.push('/')
                      }
                    })
                  }
                  break
                case 900060: // 版本需要更新
                  console.log('版本需要更新')
                  if (!store.getters.isAlertShow) {
                    await store.dispatch('app/setIsAlertShow', true)
                    MessageBox.alert(i18n.t('Message.Msg.VersionUpdatedMsg'), i18n.t('Message.Msg.VersionUpdated'), {
                      confirmButtonText: i18n.t('Shared.Confirm'),
                      callback: async action => {
                        await store.dispatch('app/setIsAlertShow', false)
                        location.reload(true)
                      }
                    })
                  }
                  break
                case 900065: // 切換渠道碼
                  await store.dispatch('user/logout')
                  console.log('切換渠道碼')
                  break
                case 900067:
                  console.log('api連打')
                  break
                default:
                  Message.error({
                    message: response.data.ErrorMsg
                  })
                  break
              }
              return Promise.resolve(response.data)
            }
            return Promise.resolve(response.data)
          } else {
            return Promise.reject(response.data)
          }
        },
        async error => {
          console.log(error)
          // 斷網 或者 請求超時 狀態
          if (!error.response) {
            // 请求超时状态
            if (error.message.includes('timeout')) {
              console.log('Time Out')
            } else {
              // 可以展示斷網組件
              console.log('Disconnect')
            }
            return
          }
          if (error && error.response) {
            switch (error.response.status) {
              case 204:
                console.log('204 No Content')
                break
              case 401:
                router.push('/401')
                break
              case 403:
                router.push('/login')
                break
              case 404:
                router.push('/404')
                break
              case 500:
                router.push('/login')
                break
              default:
                console.log(`connection error${error.response.status}`)
            }
          } else {
            console.log('error ====>', error)
          }
          return Promise.resolve(error.response)
        }
      )

      const callAPI = async (requestData) => {
        var findItem = map.find(f => f === requestData.url)
        if (findItem) {
          return new Promise((resolve, reject) => setTimeout(async () => {
            resolve(await callAPI(requestData))
          }, 1000))
        } else {
          map.push(requestData.url)
          const result = await instanse(requestData).catch(async error => {
            var findItemIndex = map.findIndex(m => m === requestData.url)
            map.splice(findItemIndex, 1)
            switch (error.ErrorCode) {
              case 900067:
                return await callAPI(requestData)
            }
            return Promise.reject(error)
          })
          // 清map
          var findItemIndex = map.findIndex(m => m === requestData.url)
          map.splice(findItemIndex, 1)
          // 判斷是否為reject
          if (result.status === undefined || result.status === null) {
            // 正常resolve
            return result
          } else {
            // reject
            return Promise.reject(result)
          }
        }
      }

      return callAPI
    }
  })

export default service
