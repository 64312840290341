import { validEnAndNum, validateContainLeastNumberAndLetter } from '../../../validate'
import i18n from '@/language'

const BindAccount_Account = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
}

const BindAccount_Password = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
}

export {
  BindAccount_Account,
  BindAccount_Password
}
