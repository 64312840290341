import {
  validateBirthday,
  validEmail,
  validateEmoji,
  validTrueName
} from '../../../validate'
import i18n from '@/language'
import store from '@/store'
import { validator } from 'agmp-utility'

const DetailInfo_Name = {
  validate(value, {}) {
    return validTrueName(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_Birthday = {
  validate(value, {}) {
    return validateBirthday(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_IDNumber = {
  validate(value, {}) {
    return validateEmoji(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_Phone = {
  validate(value, {}) {
    return validator.validPhone(store.getters.LocationCode, value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_Verify = {
  validate(value, {}) {
    return /^[0-9]{6,6}$/g.test(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_BankAccount = {
  validate(value, {}) {
    return validator.validBankAccount(store.getters.LocationCode, value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

const DetailInfo_Email = {
  validate(value, {}) {
    return validEmail(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

export {
  DetailInfo_Name,
  DetailInfo_Birthday,
  DetailInfo_IDNumber,
  DetailInfo_Phone,
  DetailInfo_Verify,
  DetailInfo_BankAccount,
  DetailInfo_Email
}
