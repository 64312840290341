import API from '../../api'
import { validator } from 'agmp-utility'
export default {
  name: 'Verify',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      keyword: process.env.VUE_APP_KEYWORD,
      isLoading: false,
      isOTPByPass: false,
      result: {
        itemObject: null
      },
      verifyForm: {
        Phone: '',
        VerificationCode: ''
      },
      myInterval: null
    }
  },
  computed: {
    timer() {
      return {
        count: this.$store.getters.TimerCount,
        lock: this.$store.getters.TimerLock,
        data: this.$store.getters.TimerTempData
      }
    }
  },
  created() {
  },
  async mounted() {
    this.isLoading = true
    const { Data, ErrorCode } = await API.System.getSkipSmsOTP().catch(err => {
      console.log(err)
      this.isLoading = false
    })
    this.isLoading = false
    if (ErrorCode === 0) {
      this.isOTPByPass = Data.skipSmsOTP
    }
    if (this.timer.lock) {
      this.verifyForm.Phone = this.timer.data.phoneNumber
      this.InitTimer(this.timer.count)
    }
  },
  methods: {
    close() {
      this.$emit('closeDialog', this.dialogName, this.result)
      this.$emit('update:show', false)
    },
    async getOTP() {
      if (!validator.validPhone(this.$store.getters.LocationCode, this.verifyForm.Phone)) {
        this.$message.error({
          message: this.$t('Message.Msg.PleaseEnterCellPhone')
        })
        return false
      }

      this.$store.commit('app/SET_ISLOADING', true)
      const { ErrorCode, Data } = await API.User.BindPhone({ CellPhoneNumber: this.verifyForm.Phone })
      this.$store.commit('app/SET_ISLOADING', false)
      if (ErrorCode === 0 && Data) {
        this.$message.success({
          message: this.$t('Message.Msg.OTPMsg')
        })
        this.$store.dispatch('timer/setTimerLock', true)
        this.$store.dispatch('timer/setTimerTempData', {
          phoneNumber: this.verifyForm.Phone
        })
        this.InitTimer()
      }
    },
    InitTimer(reloadTime = 180) {
      if (this.myInterval) {
        clearInterval(this.myInterval)
      }
      this.myInterval = setInterval(() => {
        reloadTime--
        this.$store.dispatch('timer/setTimerCount', reloadTime)
        this.$store.dispatch('timer/setTimerLock', true)
        if (reloadTime === 0) {
          this.$store.dispatch('timer/setTimerCount', 180)
          this.$store.dispatch('timer/setTimerLock', false)
          clearInterval(this.myInterval)
        }
      }, 1000)
    },
    async submit() {
      this.$refs.verifyForm.validate().then(async valid => {
        if (!valid) {
          return
        }
        if (!this.isOTPByPass) {
          if ((!this.timer.data?.phoneNumber) || this.verifyForm.Phone !== this.timer.data.phoneNumber) {
            this.$message.error(this.$t('Message.OTPPhoneNotSame'))
            return
          }
        }
        const formData = this.isOTPByPass
          ? { CellPhoneNumber: this.verifyForm.Phone }
          : 
            {
              Phone: this.verifyForm.Phone,
              VerificationCode: this.verifyForm.VerificationCode
            }
        const request = this.isOTPByPass
          ? API.User.updatePhone
          : API.User.UserVerifyPhone

        this.isLoading = true
        const { ErrorCode } = await request(formData).catch(err => {
          console.log(err)
          this.isLoading = false
        })
        if (ErrorCode === 0) {
          this.$message.success(this.$t('Shared.Success'))
          this.$store.dispatch('timer/setTimerTempData', {})
          const { ErrorCode, Data } = await API.User.getInfo().catch(e => {
            console.log(e)
            this.$store.commit('app/SET_ISLOADING', false)
          })
          if (ErrorCode === 0) {
            this.$store.commit('user/SET_MEMBERINFO', Data)
          }
          this.close()
        }
        this.isLoading = false
      })
    },
    faceBookLogout() {
      // 檢查登入狀態
      window.FB.getLoginStatus(function(response) {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB.logout(function(response) {
            console.log(response)
          })
          // })
        } else {
          // do something
          console.log('logout connected else something')
        }
      })
    },
    logout() {
      this.$store.dispatch('timer/setTimerCount', 180)
      this.$store.dispatch('timer/setTimerLock', false)
      this.$store.dispatch('timer/setTimerTempData', {})
      this.$store.commit('app/SET_ISLOADING', true)
      this.$store.dispatch('user/setToken', '')
      this.$store.dispatch('user/setDevice', '')
      this.$store.dispatch('user/setMemberInfo', {})
      this.$store.dispatch('user/setFBInfo', {})
      this.$store.dispatch('timer/setTimerTempData', null)
      this.faceBookLogout()
      location.href = '/'
    }
  }
}
