import io from 'socket.io-client'
import store from '@/store'

const SocketManagerOptions = {
  // path: 'socket.io', // string 在服務器端捕獲的路徑的名稱
  reconnection: true, // boolean 是否自動重新連接
  reconnectionAttempts: 10, // number 放棄之前重新連接嘗試的次數
  reconnectionDelay: 1500, // number 嘗試重新連接（1000）之前最初等待的時間。受+/-影響randomizationFactor，例如，默認的初始延遲將在500到1500ms之間
  randomizationFactor: 0.5, // number  0 <= randomizationFactor <= 1
  reconnectionDelayMax: 5000, // number 兩次重新連接之間要等待的最長時間（5000）。每次嘗試都會使重新連接延遲增加2倍，並如上所述進行隨機分配
  timeout: 180000, // number 發出connect_error 和connect_timeout事件之前的（Number）連接超時（20000）
  autoConnect: false, // boolean 將此值設置為false，則您必須manager.open 在確定合適的時間進行調用
  withCredentials: false
  // query: '',// 連接名稱空間時發送的其他查詢參數（然後在socket.handshake.query服務器端的對像中找到）
  // parser: '' // 要使用的解析器。默認為Parsersocket.io隨附的的實例。參見socket.io-parser。
}

const install = function(Vue, options) {
  var socket = io('', SocketManagerOptions)
  Vue.prototype.$socket = socket
}

export default { install }
