import { validateContainLeastNumberAndLetter } from '../../../validate'
import i18n from '@/language'

const LoginAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
}

const LoginPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
}

export {
  LoginAccount,
  LoginPassword
}
