import { validateContainLeastNumberAndLetter } from '../../../validate'
import i18n from '@/language'

const RegisterAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
}

const RegisterPassword = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.PwdFormat')
}

const Register_ReferrerAccount = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + i18n.t('Shared.Error')
}

export {
  RegisterAccount,
  RegisterPassword,
  Register_ReferrerAccount
}
