import { validator } from 'agmp-utility'
import { validateContainLeastNumberAndLetter } from '../../../validate'
import store from '@/store'
import i18n from '@/language'


const Account = {
  validate(value, {}) {
    return validateContainLeastNumberAndLetter(value)
  },
  params: [],
  message: () => i18n.t('Message.Msg.AccountFormat')
}

const PhoneNumber = {
  validate(value, {}) {
    return validator.validPhone(store.getters.LocationCode, value)
  },
  params: [],
  message: () => i18n.t('Shared.Format') + ' ' + i18n.t('Shared.Error')
}

export {
  Account,
  PhoneNumber
}
