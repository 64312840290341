import API from '../../api'
import { handleScreenlock, isAndroid } from '../../utils'
import screenfull from 'screenfull'
import { MessageBox } from 'element-ui'
export default {
  inject: ['getVersion', 'BGMPlay', 'BGMPause', '_', 'runWebSocket', 'closeSocket'],
  name: 'Lobby',
  data() {
    return {
      keyword: process.env.VUE_APP_KEYWORD,
      isFullscreen: true,
      isMusicPlay: true,
      otherParams: null,
      LetItSnowShow: false,
      dialog: {
        login: {
          name: 'login',
          locale: 'Login',
          show: false,
          itemObject: null
        },
        setting: {
          name: 'setting',
          locale: 'Setting',
          show: false,
          itemObject: null
        },
        promotion: {
          name: 'promotion',
          locale: 'Promotion',
          show: false,
          itemObject: null
        },
        mission: {
          name: 'mission',
          locale: 'Mission',
          show: false,
          itemObject: null
        },
        champion: {
          name: 'champion',
          locale: 'Champion',
          show: false,
          itemObject: null
        },
        announcement: {
          name: 'announcement',
          locale: 'announcement',
          show: false,
          itemObject: null
        },
        wallet: {
          name: 'wallet',
          locale: 'Wallet',
          show: false,
          itemObject: null
        },
        betRecord: {
          name: 'betRecord',
          locale: 'BetRecord',
          show: false,
          itemObject: null
        },
        specialOfferOverview: {
          name: 'specialOfferOverview',
          locale: 'specialOfferOverview',
          show: false,
          itemObject: null
        },
        advertisement: {
          name: 'advertisement',
          locale: 'Advertisement',
          show: false,
          itemObject: null
        },
        profile: {
          name: 'profile',
          locale: 'Profile',
          show: false,
          itemObject: null
        },
        leaderboard: {
          name: 'leaderboard',
          locale: 'LeaderBoard',
          show: false,
          itemObject: null
        },
        lottery: {
          name: 'lottery',
          locale: 'Lottery',
          show: false,
          itemObject: null
        },
        diamondshop: {
          name: 'diamondshop',
          locale: 'diamondshop',
          show: false,
          itemObject: null
        },
        otpvertify: {
          name: 'otpvertify',
          locale: 'otpvertify',
          show: false,
          itemObject: null
        },
        promoCode: {
          name: 'promoCode',
          locale: 'promoCode',
          show: false,
          itemObject: null
        }
      }
    }
  },
  computed: {
    token() {
      return this.$store.getters.Token
    },
    memberInfo() {
      return this.$store.getters.MemberInfo
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const params = route.params
        if (params) {
          this.otherParams = this.getOtherParams(params)
          if (this.$store.getters.Token && this.otherParams.To === 'Advertisement') {
            var formData = {}
            API.Message.Get_AdvertisementList(formData).then(res => {
              const { Data } = res
              if (Data && Array.isArray(Data) && Data.length > 0) {
                // 登入視窗關閉 打開廣告視窗
                this.openDialog(Data, 'advertisement')
              }
            }).catch(() => {
              console.log('error')
            })
          }
        }
      },
      immediate: true
    },
    isFullscreen(val) {
      if (!val && isAndroid()) {
        MessageBox.alert(this.$t('Shared.FullScreenMode'), '', {
          confirmButtonText: this.$t('Shared.Confirm'),
          callback: async action => {
            handleScreenlock()
          }
        })
      }
    },
    memberInfo: {
      handler: function(newVal, oldVal) {
        if (this.token) {
          if (newVal.PhoneNumber !== oldVal?.PhoneNumber && !newVal.PhoneNumber) {
            this.openDialog(null, 'otpvertify')
          }
        }
      },
      immediate: true,
      deep: true
    }
  },
  created() {
  },
  mounted() {
    var self = this
    // 檢查音樂
    if (Number(this.$store.state.app.isBGMEnable)) {
      this.BGMPlay()
    } else {
      this.BGMPause()
    }

    this.$nextTick(async () => {
      // 檢查有無推薦人QueryString
      await this.checkReferrerUrl()
    })

    screenfull.on('change', this.handleScreenChange)
    if (isAndroid() && screenfull.isEnabled && screen.orientation.angle === 0 && screen.orientation.angle === 180) {
      MessageBox.alert(this.$t('Shared.FullScreenMode'), '', {
        confirmButtonText: this.$t('Shared.Confirm'),
        callback: async action => {
          handleScreenlock()
        }
      })
    }
  },
  beforeDestroy() {
    screenfull.off('change', this.handleScreenChange)
  },
  methods: {
    checkToken() {
      if (this.$store.getters.Token === null || this.$store.getters.Token === '') {
        this.openDialog(null, 'login')
      }
    },
    getOtherParams(params) {
      return Object.keys(params).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = params[cur]
        }
        return acc
      }, {})
    },
    async checkReferrerUrl() {
      var qs = this.$route.query
      if (qs.cmd !== undefined && qs.cmd === 'referrer') {
        const item = {
          ac: qs.ac,
          m: qs.m,
          cmd: qs.cmd,
          token: qs.token
        }
        await this.$store.dispatch('user/setRefLinkData', item)
        await this.$router.push('/slots')
        this.openDialog(item, 'login')
      } else {
        // 檢查Token
        this.checkToken()
      }
    },
    openDialog(entry, action) {
      // var self = this
      switch (action) {
        case 'login':
          this.dialog.login.show = true
          this.dialog.login.itemObject = entry
          break
        case 'setting':
          this.dialog.setting.show = true
          this.dialog.setting.itemObject = entry
          this.$track('打開設定頁', {
            event_category: 'click',
            event_label: '打開設定頁'
          })
          break
        case 'promotion':
          this.dialog.promotion.show = true
          this.dialog.promotion.itemObject = entry
          this.$track('打開活動看板', {
            event_category: 'click',
            event_label: '打開活動看板'
          })
          break
        case 'mission':
          this.dialog.mission.show = true
          this.$track('打開每日任務', {
            event_category: 'click',
            event_label: '打開每日任務'
          })
          break
        case 'champion':
          this.dialog.champion.show = true
          break
        case 'announcement':
          this.dialog.announcement.show = true
          this.$track('打開系統公告', {
            event_category: 'click',
            event_label: '打開系統公告'
          })
          break
        case 'wallet':
          if (this.$store.getters.IsAvoidFlow) return
          this.dialog.wallet.show = true
          this.dialog.wallet.itemObject = entry
          this.$track('打開錢包', {
            event_category: 'click',
            event_label: '打開錢包'
          })
          break
        case 'betRecord':
          this.dialog.betRecord.show = true
          this.$track('打開下注紀錄', {
            event_category: 'click',
            event_label: '打開下注紀錄'
          })
          break
        case 'specialOfferOverview':
          this.dialog.specialOfferOverview.show = true
          this.dialog.specialOfferOverview.itemObject = entry
          this.$track('打開存款贈總覽', {
            event_category: 'click',
            event_label: '打開存款贈總覽'
          })
          break
        case 'profile':
          this.dialog.profile.show = true
          this.dialog.profile.itemObject = entry
          this.$track('打開個人資料', {
            event_category: 'click',
            event_label: '打開個人資料'
          })
          break
        case 'lottery':
          this.dialog.lottery.show = true
          this.$track('打開抽獎頁', {
            event_category: 'click',
            event_label: '打開抽獎頁'
          })
          break
        case 'diamondshop':
          this.dialog.diamondshop.show = true
          this.$track('打開鑽石商城', {
            event_category: 'click',
            event_label: '打開鑽石商城'
          })
          break
        case 'advertisement':
          this.dialog.advertisement.show = true
          this.dialog.advertisement.itemObject = entry
          break
        case 'leaderboard':
          this.dialog.leaderboard.show = true
          break
        case 'otpvertify':
          this.dialog.otpvertify.show = true
          break
        case 'promoCode':
          this.$track('打開優惠碼頁面', {
            event_category: 'click',
            event_label: '打開優惠碼頁面'
          })
          this.dialog.promoCode.show = true
          break
      }
    },
    closeDialog(dialogName, result) {
      var obj = {}
      switch (dialogName) {
        case 'login':
          var formData = {}
          this.$store.commit('app/SET_ISLOADING', true)
          API.Message.Get_AdvertisementList(formData).then(res => {
            this.$store.commit('app/SET_ISLOADING', false)
            const { Data } = res
            if (Data && Array.isArray(Data) && Data.length > 0) {
              // 登入視窗關閉 打開廣告視窗
              this.openDialog(Data, 'advertisement')
            }
          }).catch(() => {
            this.$store.commit('app/SET_ISLOADING', false)
            console.log('error')
          })
          break
        case 'setting':
          break
        case 'promotion':
          obj = result.itemObject
          if (obj && obj.action === 'openDialog' && obj.from === 'promotion' && obj.to === 'DetailInfo') {
            this.openDialog(obj, 'profile')
            break
          }
          if (obj && obj.action === 'openDialog' && obj.from === 'SpecialOffer' && obj.to === 'Deposit') {
            this.openDialog(obj, 'wallet')
            break
          }
          if (obj && obj.action === 'openDialog' && obj.from === 'promotion' && obj.to === 'lottery') {
            this.openDialog(obj, 'lottery')
            break
          }
          this.$store.dispatch('wallet/getCheckPromotion')
          break
        case 'mission':
          obj = result.itemObject
          if (obj && obj.action === 'openDialog' && obj.from === 'mission' && obj.to === 'DetailInfo') {
            this.openDialog(obj, 'profile')
            break
          }
          this.$store.dispatch('dailyMission/getHasReward')
          break
        case 'champion':
          break
        case 'announcement':
          this.$store.dispatch('systemMail/getHasNoRead')
          break
        case 'leaderboard':
          break
        case 'wallet':
          obj = result.itemObject
          if (obj && obj.action === 'openDialog' && obj.from === 'wallet' && obj.to === 'DetailInfo') {
            this.openDialog(obj, 'profile')
          }
          if (obj && obj.action === 'openDialog' && obj.from === 'wallet' && obj.to === 'promotion') {
            this.openDialog(obj, 'promotion')
          }
          if (obj && obj.action === 'openDialog' && obj.from === 'wallet' && obj.to === 'specialOfferOverview') {
            this.openDialog(obj, 'specialOfferOverview')
          }
          break
        case 'betRecord':
          break
        case 'specialOfferOverview':
          obj = result.itemObject
          if (obj && obj.action === 'openDialog' && obj.from === 'SpecialOffer' && obj.to === 'Deposit') {
            this.openDialog(obj, 'wallet')
            break
          }
          if (obj && obj.action === 'openDialog' && obj.from === 'SpecialOffer' && obj.to === 'DetailInfo') {
            this.openDialog(obj, 'setting')
            break
          }
          break
        case 'advertisement':
          if (result.itemObject !== null && result.itemObject !== undefined) {
            this.$track('點擊廣告', {
              event_category: 'click',
              event_label: '點擊廣告'
            })
            switch (result.itemObject.keyword) {
              case 'activityboard':
                // 活動看板
                this.openDialog(null, 'promotion')
                break
              case 'missionboard':
                // 任務看板
                this.openDialog(null, 'mission')
                break
              case 'depositpackage':
                // 存款頁
                this.openDialog(null, 'wallet')
                break
              case 'service':
                // 客服中心
                // TODO:還沒有客服中心
                this.openDialog(null, '')
                break
              case 'depositactivity':
                // 存款贈
                this.openDialog({ type: 7 }, 'promotion')
                break
              case 'lottery':
                // 抽獎活動
                this.openDialog(null, 'lottery')
                break
              case 'link':
                // 開啟鏈結
                window.open(result.itemObject.url, '_blank')
                break
            }
          }
          break
        case 'profile':
          obj = result
          if (['wallet', 'Deposit'].includes(obj?.to)) {
            this.openDialog(obj, 'wallet')
            break
          }
          if (result?.to === 'Promotion') {
            this.openDialog(result, 'promotion')
            break
          }
          if (result?.to === 'Lottery') {
            this.openDialog(result, 'lottery')
            break
          }
          if (obj?.to === 'diamondShop') {
            this.openDialog(obj, 'diamondshop')
            break
          }
          break
        case 'lottery':
          this.$store.dispatch('user/getHasLottery')
          break
        case 'diamondshop':
          this.$store.dispatch('user/getHasDiamondShop')
          break
        case 'promoCode':
          if (result?.to === 'Deposit') {
            this.openDialog(result, 'wallet')
          } else if (result?.to === 'Promotion') {
            this.openDialog(result, 'promotion')
          } else if (result?.to === 'Lottery') {
            this.openDialog(result, 'lottery')
          }
          break
      }
      if (this.$store.getters.Token !== null && this.$store.getters.Token !== '' && dialogName !== 'login') {
        this.$store.dispatch('user/getWallet')
      }
    },
    handleScreenChange() {
      this.isFullscreen = screenfull.isFullscreen
    }
  }
}
