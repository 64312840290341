import API from '../../api'
import { MessageBox } from 'element-ui'
import { getMobile } from '../../utils'

export default {
  inject: ['BGMPlay', 'BGMPause', 'playSound', 'checkUserNotificationIsEnabled'],
  name: 'Setting',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.getters.isMemberInfoLoading
    },
    appVersion: function() {
      return this.$store.getters.Version
    },
    musicEnable: function() {
      return this.$store.state.app.isBGMEnable
    },
    langOptions: function() {
      return this.langData.map(lang => ({
        value: lang.Code,
        label: lang.Name
      }))
    },
    MemberInfoData() {
      return this.$store.getters.MemberInfo
    }
  },
  data() {
    return {
      result: {
        itemObject: null
      },
      langData: [],
      dialog: {
        changePassword: {
          name: 'changePassword',
          locale: 'changePassword',
          show: false,
          itemObject: null
        }
      },
      canSubScribe: false
    }
  },
  async mounted() {
    this.$nextTick(async () => {
      this.checkToken()
      this.getLanguage()
      this.getMemberInfo()
      const device = getMobile()
      if (device === 'iPhone' || device === 'iPad' || device === 'iPod') return
      this.getSubscriptionState()
        .then(state => {
          console.log(state)
          this.canSubScribe = state ? (!state.isPushEnabled || state.isOptedOut) : false
        })
      window.OneSignal.on('subscriptionChange', (isSubscribed) => {
        if (isSubscribed) {
          this.canSubScribe = false
        }
      })
    })
  },
  methods: {
    checkToken() {
      if (!this.$store.getters.Token) {
        this.openDialog(null, 'login')
      }
    },
    close() {
      if (this.isLoading) return
      this.langShow = false
      this.$emit('closeDialog', this.dialogName, this.result)
      this.$emit('update:show', false)
    },
    openDialog(entry, action) {
      this.playSound('open')
      switch (action) {
        case 'memberInfo':
          this.$track('打開會員資訊頁', {
            event_category: 'click',
            event_label: '打開會員資訊頁'
          })
          this.dialog.memberInfo.show = true
          this.dialog.memberInfo.itemObject = entry
          break
        case 'changePassword':
          this.$track('打開修改密碼頁', {
            event_category: 'click',
            event_label: '打開修改密碼頁'
          })
          this.dialog.changePassword.show = true
          break
        case 'referrer':
          this.dialog.referrer.show = true
          break
        case 'bindAccount':
          this.$track('打開綁定帳號頁', {
            event_category: 'click',
            event_label: '打開綁定帳號頁'
          })
          this.dialog.bindAccount.show = true
          break
        case 'Invite':
          this.$track('打開邀請連結頁', {
            event_category: 'click',
            event_label: '打開邀請連結頁'
          })
          this.dialog.Invite.show = true
          break
        case 'confirm':
          this.langShow = false
          this.dialog.confirm.show = true
          this.dialog.confirm.itemObject = {
            action: 'reload',
            From: 'Confirm',
            To: 'Setting',
            data: entry
          }
          break
      }
    },
    closeDialog(dialogName, result) {
      switch (dialogName) {
        case 'memberInfo':
          this.getMemberInfo()
          break
        case 'changePassword':
          break
        case 'referrer':
          this.getMemberInfo()
          break
        case 'bindAccount':
          this.getMemberInfo()
          break
        case 'Invite':
          break
        case 'confirm':
          this.dialog.confirm.show = false
          if (result.itemObject && result.itemObject.action === 'reload') {
            this.changeLanguage(result.itemObject.data.Code)
            this.$track('語系切換', {
              event_category: 'click',
              event_label: '語系切換',
              value: result.itemObject.data.Code
            })
            window.location.reload(true)
          }
          break
      }
    },
    logout() {
      // 沒綁定帳號時跳提示
      if (!this.MemberInfoData.AccountBind && !this.MemberInfoData.FbBind) {
        MessageBox.confirm(this.$t('Message.BindAccountMsg'), '', {
          confirmButtonText: this.$t('Shared.BindAccount'),
          cancelButtonText: this.$t('Shared.Logout'),
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        })
          .then(() => {
            this.openDialog(null, 'bindAccount')
          })
          .catch(() => {
            this.logoutAction()
          })
      } else {
        this.logoutAction()
      }
    },
    logoutAction() {
      this.$track('登出', {
        event_category: 'click',
        event_label: '登出'
      })
      this.$store.commit('app/SET_ISLOADING', true)
      // 登出 清空 Token
      this.$store.dispatch('user/setToken', '')
      // 清空 Device
      this.$store.dispatch('user/setDevice', '')
      // 清空 MemberInfo
      this.$store.dispatch('user/setMemberInfo', {})
      // 清掉 Vuex FBInfo
      this.$store.dispatch('user/setFBInfo', {})
      // 清掉 暫存的手機號碼
      this.$store.dispatch('timer/setTimerTempData', null)
      this.$store.dispatch('timer/setTimerLock', false)
      this.$store.dispatch('timer/setFirstClick', false)
      this.$store.dispatch('timer/setTimerCount', 180)
      // FB Logout
      this.faceBookLogout()
      location.href = '/'
    },
    async getLanguage() {
      var formData = {}
      const { ErrorCode, Data } = await API.Language.GetList(formData)
      if (ErrorCode === 0 && Data) {
        this.langData = Data.filter(function(f) {
          return f.Status === 1
        })
      }
    },
    changeLanguage(langCode) {
      if (langCode === this.$store.getters.language) return

      this.$i18n.locale = langCode
      this.$store.dispatch('app/setLanguage', langCode)
    },
    async getMemberInfo() {
      await this.$store.dispatch('user/getMemberInfo')
    },
    faceBookLogout() {
      // 檢查登入狀態
      window.FB?.getLoginStatus(function(response) {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB?.logout(function(response) {
            console.log(response)
          })
          // })
        } else {
          // do something
          console.log('logout connected else something')
        }
      })
    },
    handleMusicChange(v) {
      this.$store.dispatch('app/setBGMEnable', v)
      this.$track('設定BGM', {
        event_category: 'click',
        event_label: '設定BGM',
        value: v
      })
      v ? this.BGMPlay() : this.BGMPause()
    },
    async getSubscriptionState() {
      return Promise.all([
        window.OneSignal.isPushNotificationsEnabled(),
        window.OneSignal.isOptedOut()
      ]).then(function(result) {
        var isPushEnabled = result[0]
        var isOptedOut = result[1]

        return {
          isPushEnabled: isPushEnabled,
          isOptedOut: isOptedOut
        }
      })
    },
    handleSubscribe() {
      this.$track('訂閱通知', {
        event_category: 'click',
        event_label: '訂閱通知'
      })
      this.getSubscriptionState().then(function(state) {
        if (state.isPushEnabled) {
          /* Subscribed, opt them out */
          // window.OneSignal.setSubscription(false)
        } else {
          if (state.isOptedOut) {
            /* Opted out, opt them back in */
            window.OneSignal.setSubscription(true)
          } else {
            /* Unsubscribed, subscribe them */
            window.OneSignal.registerForPushNotifications()
          }
        }
      })
    }
  }
}
