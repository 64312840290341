import API from '../../api'
import { handleScreenlock } from '../../utils'
import { isEqual } from 'lodash'

export default {
  inject: ['_', 'isSafari', 'playSound'],
  name: 'Lobby',
  data() {
    return {
      isLoading: false,
      isProviderLoading: false,
      isMenuExpand: false,
      gameList: [],
      GameTypeList: [],
      GameProviderList: [],
      logo: require(`@/assets/images/${process.env.VUE_APP_KEYWORD}/logo.webp`)
    }
  },
  computed: {
    token() {
      return this.$store.getters.Token
    },
    favGames() {
      return this.$store.getters.favGames
    },
    memberInfo() {
      return this.$store.getters.MemberInfo
    },
    gameFilter() {
      return this.$store.getters.GameFilter
    },
    ProviderInfo() {
      return this.$store.getters.ProviderInfo
    },
    language() {
      return this.$store.getters.language
    }
  },
  watch: {
    token: {
      handler(val) {
        if (val) {
          this.getGameType()
          this.initGameProviderList()
          this.getGameList()
        }
      },
      immediate: true
    },
    memberInfo: {
      handler(newVal, oldVal) {
        if (newVal && oldVal && newVal.Level !== oldVal.Level && oldVal.Type && this.token) {
          this.getGameType()
          this.initGameProviderList()
          this.getGameList()
        }
      }
    },
    gameFilter: {
      deep: true,
      immediate: true,
      handler(newVal, oldVal) {
        if (!this.token) return
        if (!oldVal) return
        if (newVal?.gameType === oldVal?.gameType && newVal?.provider === oldVal?.provider) return

        this.getGameList()
      }
    },
    language(v) {
      this.getGameList()
    }
  },
  methods: {
    getGameList() {
      let ProviderID = this.gameFilter.providerId
      const ProviderName = this.gameFilter.provider
      let isTop50 = false
      let isFav = false
      if (ProviderName === 'all') {
        ProviderID = undefined
      }
      if (ProviderName === 'top50') {
        ProviderID = undefined
        isTop50 = true
      }
      if (ProviderName === 'fav') {
        ProviderID = undefined
        isFav = true
      }
      var formData = {
        ProviderID: ProviderID || null,
        GameTypeIDs: this.gameFilter.gameType
      }
      this.gameList = []
      this.isLoading = true
      if (isTop50) {
        API.Game.GetTop50Game({ GameTypeID: this.gameFilter.gameType ? this.gameFilter.gameType : undefined }).then(res => {
          this.gameList = res.Data
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        API.Game.Get_GameList(formData).then(res => {
          const { Data } = res
          if (Data && Array.isArray(Data)) {
            let list = []
            if (isFav) list = this.handleFavData(Data)
            else list = Data
            this.gameList = list
          }
        }).finally(() => {
          this.isLoading = false
        })
      }
    },
    openGame(obj) {
      this.$track('開啟遊戲', {
        event_category: 'click',
        event_label: '開啟遊戲',
        value: obj.GameID
      })
      var formData = {
        GameID: obj.GameID,
        IsDemo: obj.IsDemo
      }
      this.$store.commit('app/SET_ISLOADING', true)
      API.Game.OpenGame(formData).then(res => {
        this.$store.commit('app/SET_ISLOADING', false)
        if (res.Data && res.Data.GameUrl !== '') {
          handleScreenlock({
            open: true,
            orientation: obj.Orientation
          })
          this.$store.dispatch('app/setLaunchGame', res.Data)
          this.$router.push({
            // path: '/gameview',
            name: 'GameView',
            query: {
              Orientation: obj.Orientation
            }
          })
        }
      }).catch(() => {
        this.$store.commit('app/SET_ISLOADING', false)
        console.log('error')
      })
    },
    handleFavData(data) {
      return this.favGames.reduce((acc, curr) => {
        const findGame = data.find(game => game.GameID === curr)
        if (findGame) return [...acc, findGame]
        return acc
      }, [])
    },
    handleAddFav(item) {
      if (this.favGames.includes(item.GameID)) {
        this.$store.dispatch('app/setFavGame', this.favGames.filter(id => id !== item.GameID))
      } else {
        this.$store.dispatch('app/setFavGame', [...this.favGames, item.GameID])
      }
    },
    handleHeartClass(item) {
      return {
        active: this.favGames.includes(item.GameID)
      }
    },
    handleGameTypeClass(type) {
      switch (type) {
        case 'Table Game':
        case 'Card Game':
          return 'table'
        case 'Arcade Game Fisher':
          return 'fisher'
        case 'Arcade Game':
          return 'arcade'
        case 'Live Game':
          return 'live'
        case 'Slot Game':
        default:
          return 'slot'
      }
    },
    handleMenuExpand() {
      this.isMenuExpand = !this.isMenuExpand
      this.playSound()
    },
    getGameType() {
      this.isLoading = true
      API.Game.Get_GameType().then(({ Data }) => {
        if (Data) {
          this.GameTypeList = Data
          if (!Data.some(v => isEqual(v.GameTypeIDs, this.gameFilter.gameType))) {
            this.$store.dispatch('app/setGameFilter', { ...this.gameFilter, gameType: null })
          }
        }
      }).catch(() => {
        console.log('error')
      }).finally(() => {
        this.isLoading = false
      })
    },
    handleGameTypeIcon(type) {
      if (type === 'Slot Game') return require('@/assets/images/lobby/slot.webp')
      if (type === 'Arcade Game Fisher') return require('@/assets/images/lobby/fisher.webp')
      if (type === 'Arcade Game') return require('@/assets/images/lobby/arcade.webp')
      if (type === 'Live Game') return require('@/assets/images/lobby/LIVE.webp')
      return require('@/assets/images/lobby/table.webp')
    },
    async onGameProviderClick(data) {
      this.$track('GameFilter切換GameProvider', {
        event_category: 'GameFilter',
        event_label: 'GameFilter切換GameProvider',
        value: this._.isString(data) ? data : data.ProviderName
      })
      this.$store.dispatch('app/setGameFilter', {
        gameType: this._.isString(data) ? null : this.gameFilter.gameType,
        provider: this._.isString(data) ? data : data.ProviderName,
        providerId: this._.isString(data) ? null : data.ProviderID
      })
      this.isMenuExpand = false
      this.playSound()
    },
    initGameProviderList() {
      if (this.isProviderLoading) return
      var formData = {
        GameTypeIDs: this.gameFilter.gameType
      }
      this.isProviderLoading = true
      API.Game.Get_GameProviderList(formData).then(res => {
        if (res.Data && Array.isArray(res.Data) && res.Data.length > 0) {
          this.GameProviderList = res.Data
          this.$store.dispatch('app/setProviderList', res.Data)
        } else {
          this.GameProviderList = []
          this.$store.dispatch('app/setProviderList', [])
        }
        if (this.gameFilter.providerId !== null && !res.Data.some(v => v.ProviderID === this.gameFilter.providerId)) {
          this.$store.dispatch('app/setGameFilter', { ...this.gameFilter, provider: 'top50', providerId: null })
        }
      }).catch(() => {
        console.log('error')
      }).finally(() => {
        this.isProviderLoading = false
      })
    },
    onGameTypeClick(id) {
      this.$track('GameFilter切換Gametype', {
        event_category: 'GameFilter',
        event_label: 'GameFilter切換Gametype',
        value: id
      })
      this.$store.dispatch('app/setGameFilter', {
        gameType: id,
        provider: 'all',
        providerId: null
      })
      if (!id) {
        this.isMenuExpand = false
      }
      this.initGameProviderList()
      this.playSound()
    },
    checkGameTypeActive(item) {
      return isEqual(this.gameFilter.gameType, item.GameTypeIDs)
    }
  }
}
