import API from '../../api'

import { v4 as uuidV4 } from 'uuid'

import { MessageBox } from 'element-ui'

import ClickOutside from 'vue-click-outside'

export default {
  inject: ['checkUserNotificationIsEnabled', '_'],
  directives: {
    ClickOutside
  },
  name: 'LogIn',
  props: {
    title: {
      type: String,
      default: ''
    },
    dialogName: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    },
    itemObject: {
      type: Object || Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      isLogin: false,
      keyword: process.env.VUE_APP_KEYWORD,
      result: {
        itemObject: null
      },
      form: {
        Account: '',
        Password: '',
        email: ''
      },
      login: {},
      isPwdShow: false,
      eyeIcon: require('@/assets/images/common/eye.svg'),
      eyeOpenIcon: require('@/assets/images/common/eye-open.svg'),
      dialog: {
        register: {
          name: 'register',
          locale: 'Register',
          show: false,
          itemObject: null
        },
        forgotPassword: {
          name: 'forgotPassword',
          locale: 'ForgotPassword',
          show: false,
          itemObject: null
        }
      },
      isContactShow: false,
      contactInfo: [],
      isLivechatOpen: false,
      isZendeskLoaded: false
    }
  },
  computed: {
    isMemberInfoLoading() {
      return this.$store.state.user.isMemberInfoLoading
    },
    contactList() {
      return this.contactInfo.filter(item => ['Line@', 'LiveChat', 'Zendesk', 'Viber', 'Tawk', 'JivoChat', 'Telegram', 'Custom'].includes(item.Name) && item.Status === 1)
    },
    liveChatInfo() {
      if (!this.contactInfo) return {}
      const find = this.contactInfo.find(item => item.Name === 'LiveChat')
      return (find && find.Status === 1 ? find : {})
    }
  },
  watch: {
    isMemberInfoLoading(val) {
      if (val) {
        this.$store.commit('app/SET_ISLOADING', true)
      } else {
        this.$store.commit('app/SET_ISLOADING', false)
        this.close()
      }
    },
    zendeskInfo: {
      deep: true,
      handler(val) {
        if (this._.isEmpty(val)) return

        this.$zendesk.load(val.ContactInfo)
        this.$zendesk.$on('loaded', () => {
          this.$zendesk.zE('webWidget:on', 'close', () => {
            this.$zendesk.hide()
          })
        })
      }
    }
  },
  created() {
  },
  mounted() {
    if (!this.$store.getters.AgentCode) {
      this.$message.error({
        message: 'No Agent Code'
      })
      this.$router.push('/')
    }
    if (this.itemObject && this.itemObject.cmd !== undefined && this.itemObject.cmd === 'referrer') {
      this.openDialog(this.itemObject, 'register')
    }
    this.getContactInfo()
  },
  methods: {
    openDialog(entry, action) {
      switch (action) {
        case 'register':
          this.dialog.register.show = true
          this.dialog.register.itemObject = entry
          break
        case 'forgotPassword':
          this.dialog.forgotPassword.show = true
          break
      }
    },
    async getContactInfo() {
      const res = await API.Community.Get_CommunityLink()
      if (res.ErrorCode === 0) {
        this.contactInfo = res.Data
      }
    },
    closeDialog(dialogName, result) {
      switch (dialogName) {
        case 'register':
          var self = this
          if (result.itemObject && result.itemObject.type === 'register') {
            this.form.Account = result.itemObject.Account
            this.form.Password = result.itemObject.Password
            // loading
            this.$nextTick(async () => {
              await this.normalLogin()
            })
          }
          break
        case 'forgotPassword':
          break
      }
    },
    close() {
      this.$emit('closeDialog', this.dialogName, this.result)
      this.$emit('update:show', false)
    },
    normalLogin() {
      this.$refs.form.validate().then(async valid => {
        if (valid) {
          var formData = {
            Action: 'Account',
            AccountInfo: {
              Account: this.form.Account,
              Password: this.form.Password
            },
            OauthInfo: {
              Token: ''
            },
            DeviceInfo: {
              DeviceID: ''
            },
            AgentCode: this.$store.getters.AgentCode
          }
          // loading
          this.$store.commit('app/SET_ISLOADING', true)
          const result = await API.User.Login(formData)
          if (result.ErrorCode === 0) {
            this.$track('一般登入', {
              event_category: 'Login',
              event_label: '一般登入'
            })
            await this.$store.dispatch('user/setToken', result.Data.tn)
            this.checkUserNotificationIsEnabled()
          } else if (result.ErrorCode === 900065) {
            this.$store.commit('app/SET_ISLOADING', false)
            MessageBox.confirm(result.Data.Tips, '', {
              confirmButtonText: this.$t('Shared.Confirm'),
              cancelButtonText: this.$t('Shared.Cancel')
            }).then(res => {
              // 執行切換 並自動登入
              const agent = result.Data.AgentCode
              this.$store.dispatch('user/setAgentCode', agent)
              console.log('切換渠道的AgentCode ===> ', agent)
              API.System.CheckAgentCode({ AgentCode: result.Data.AgentCode })
                .then(res => {
                  this.$store.dispatch('app/setLocationID', res.Data.LocationID)
                  this.$store.dispatch('app/setLocationCode', res.Data.LocationCode.toLowerCase())
                  this.normalLogin()
                })
            }).catch(() => {
              // 不執行 維持原樣
            })
          } else {
            this.$store.commit('app/SET_ISLOADING', false)
          }
        } else {
          console.log('error')
        }
      })
    },
    lineLogin() {
    },
    faceBookLogin() {
      this.$track('Facebook登入', {
        event_category: 'Login',
        event_label: 'Fackbook登入'
      })
      const vm = this
      // 檢查登入狀態
      this.$store.commit('app/SET_ISLOADING', true)
      try {
        window.FB.getLoginStatus(function(response) {
          // 登入狀態 - 已登入
          if (response.status === 'connected') {
            // 獲取用戶個人資料
            vm.getFBProfile()
            // 登入
            // console.log(window.localStorage.getItem('FBInfo'))
            var accessToken = window.FB.getAccessToken()
            var formData = {
              Action: 'FB',
              AccountInfo: {
                Account: '',
                Password: ''
              },
              OauthInfo: {
                Token: accessToken
              },
              DeviceInfo: {
                DeviceID: ''
              },
              AgentCode: vm.$store.getters.AgentCode
            }
            vm.$nextTick(async () => {
              const result = await API.User.Login(formData)
              if (result.ErrorCode === 0) {
                await vm.$store.dispatch('user/setToken', result.Data.tn)
                // 撈取個人資訊
                // 獲取用戶個人資料
                vm.getFBProfile()
                vm.$message.success({
                  message: 'Facebook ' + vm.$t('Shared.Login') + ' ' + vm.$t('Shared.Success')
                })
                if (result.Data?.isNewMember) {
                  vm.$router.replace(
                    {
                      query: {
                        register: true
                      }
                    }
                  )
                }
              } else if (result.ErrorCode === 900065) {
                vm.$store.commit('app/SET_ISLOADING', false)
                console.log('login, AccessToken:', accessToken)
                await vm.FBCreateCheck(result.Data.IsCreate, accessToken, result.Data.AgentCode, result.Data.Tips)
              } else {
                vm.$store.commit('app/SET_ISLOADING', false)
                // 失敗跑FB logout
                vm.faceBookLogout()
              }
            })
          } else {
            // 登入狀態 - 未登入
            // 用戶登入(確認授權)
            window.FB.login(
              function(res) {
                console.log(res)
                const { authResponse } = res
                if (!authResponse?.accessToken) {
                  vm.$message.error(`Facebook ${vm.$t('Shared.Login')} ${vm.$t('Shared.Fail')}`)
                  vm.$store.commit('app/SET_ISLOADING', false)
                  vm.faceBookLogout()
                  return
                }
                var formData = {
                  Action: 'FB',
                  AccountInfo: {
                    Account: '',
                    Password: ''
                  },
                  OauthInfo: {
                    Token: authResponse.accessToken
                  },
                  DeviceInfo: {
                    DeviceID: ''
                  },
                  AgentCode: vm.$store.getters.AgentCode
                }
                vm.$nextTick(async () => {
                  const result = await API.User.Login(formData)
                  if (result.ErrorCode === 0) {
                    await vm.$store.dispatch('user/setToken', result.Data.tn)
                    // 撈取個人資訊
                    // 獲取用戶個人資料
                    vm.getFBProfile()
                    vm.$message.success({
                      message: 'Facebook ' + vm.$t('Shared.Login') + ' ' + vm.$t('Shared.Success')
                    })
                    if (result.Data?.isNewMember) {
                      vm.$router.replace(
                        {
                          query: {
                            register: true
                          }
                        }
                      )
                    }
                  } else if (result.ErrorCode === 900065) {
                    console.log('Not login, AccessToken:', authResponse.accessToken)
                    await vm.FBCreateCheck(result.Data.IsCreate, authResponse.accessToken, result.Data.AgentCode, result.Data.Tips)
                    vm.$store.commit('app/SET_ISLOADING', false)
                  } else {
                    // 失敗跑FB logout
                    vm.faceBookLogout()
                    vm.$store.commit('app/SET_ISLOADING', false)
                  }
                })
              },
              // 授權 - 個人資料&Email
              { scope: 'public_profile,email' }
            )
          }
        })
      } catch (err) {
        console.log('FB Login Error', err)
        this.$message.error(`Facebook ${this.$t('Shared.Login')} ${this.$t('Shared.Fail')}`)
        this.$store.commit('app/SET_ISLOADING', false)
        this.faceBookLogout()
      }
    },
    faceBookLogout() {
      // 檢查登入狀態
      window.FB.getLoginStatus(function(response) {
        // 檢查登入狀態
        if (response.status === 'connected') {
          // 移除授權
          // window.FB.api('/me/permissions', 'DELETE', function(res) {
          // 用戶登出
          window.FB.logout(function(response) {
            console.log(response)
          })
          // })
        } else {
          // do something
        }
      })
    },
    async FBCreateCheck(IsCreate, Token, AgentCode, msg) {
      var vm = this
      if (IsCreate) {
        MessageBox.confirm(msg, '', {
          confirmButtonText: vm.$t('Shared.Confirm'),
          cancelButtonText: vm.$t('Shared.Create') + vm.$t('Shared.Account'),
          showClose: false,
          closeOnClickModal: false,
          closeOnPressEscape: false
        }).then(async res => {
          // 執行切換 並自動登入
          vm.$store.dispatch('user/setAgentCode', AgentCode)
          API.System.CheckAgentCode({ AgentCode })
            .then(res => {
              this.$store.dispatch('app/setLocationID', res.Data.LocationID)
              this.$store.dispatch('app/setLocationCode', res.Data.LocationCode.toLowerCase())
              vm.faceBookLogin()
            })
        }).catch(async () => {
          // 執行建立帳號
          vm.$store.commit('app/SET_ISLOADING', true)
          const FBCreateResult = await API.User.FBAccountCreate({
            Token: Token
          })
          if (FBCreateResult.ErrorCode === 0) {
            // 不執行切換渠道但要跑FBLogin
            vm.$router.replace(
              {
                query: {
                  register: true
                }
              }
            )
            vm.faceBookLogin()
          }
          vm.$store.commit('app/SET_ISLOADING', false)
        })
      } else {
        MessageBox.confirm(msg, '', {
          confirmButtonText: vm.$t('Shared.Confirm'),
          cancelButtonText: vm.$t('Shared.Cancel')
        }).then(res => {
          // 執行切換 並自動登入
          vm.$store.dispatch('user/setAgentCode', AgentCode)
          // console.log('切換渠道的AgentCode ===> ', agent)
          vm.faceBookLogin()
        }).catch(() => {
          // 不執行 維持原樣
          vm.$store.commit('app/SET_ISLOADING', false)
        })
      }
    },
    getFBProfile() {
      var self = this
      window.FB.api('/me?fields=name,id,email,picture', function(res) {
        self.$store.dispatch('user/setFBInfo', res)
      })
    },
    autoGetFBProfile() {
      const vm = this
      window.FB.getLoginStatus(res => {
        if (res.status === 'connected') {
          console.log('connected:', res)
          vm.$nextTick(async () => {
            vm.getFBProfile()
          })
        } else {
          window.FB.login(
            function(res) {
              const { authResponse } = res
              if (authResponse.userID === vm.$store.getters.MemberInfo.FBID) {
                vm.$nextTick(async () => {
                  vm.getFBProfile()
                })
              }
            },
            { scope: 'public_profile,email' }
          )
        }
      })
    },
    quickLogin() {
      this.$track('快速登入', {
        event_category: 'Login',
        event_label: '快速登入'
      })
      this.$nextTick(async () => {
        var deviceID = uuidV4()
        var formData = {
          Action: 'Quick',
          AccountInfo: {
            Account: this.form.Account,
            Password: this.form.Password
          },
          OauthInfo: {
            Token: ''
          },
          DeviceInfo: {
            DeviceID: deviceID
          },
          AgentCode: this.$store.getters.AgentCode
        }
        // loading
        this.$store.commit('app/SET_ISLOADING', true)
        const result = await API.User.Login(formData)
        if (result.ErrorCode === 0) {
          await this.$store.dispatch('user/setDeviceID', deviceID)
          await this.$store.dispatch('user/setToken', result.Data.tn)
          // 撈取個人資訊
          this.checkUserNotificationIsEnabled()
          // get wallet
          this.close()
        } else {
          console.log('error')
        }
        this.$store.commit('app/SET_ISLOADING', false)
      })
    },
    openSignUp() {
      this.$track('打開註冊頁', {
        event_category: 'click',
        event_label: '打開註冊頁'
      })
      this.openDialog(null, 'register')
    },
    openForgotPwd() {
      this.$track('打開忘記密碼頁', {
        event_category: 'click',
        event_label: '打開忘記密碼頁'
      })
      this.openDialog(null, 'forgotPassword')
    },
    handleOpenLiveChat() {
      if (window.LiveChatWidget && window.LiveChatWidget.call) {
        if (this.isLivechatOpen) {
          console.log('close live chat')
          window.LiveChatWidget.call('hide')
          this.isLivechatOpen = false
          window.LiveChatWidget.off('visibility_changed', this.handleLiveChatChange)
        } else {
          console.log('open live chat')
          window.LiveChatWidget.call('maximize')
          this.isLivechatOpen = true
          window.LiveChatWidget.on('visibility_changed', this.handleLiveChatChange)
        }
      }
    },
    handleLiveChatChange(params) {
      console.log('live chat change', params)
      if (params.visibility === 'minimized') {
        console.log('close live chat', params)
        window.LiveChatWidget.call('hide')
        this.isLivechatOpen = false
        window.LiveChatWidget.off('visibility_changed', this.handleLiveChatChange)
      }
    },
    handleContactShow() {
      this.isContactShow = !this.isContactShow
    },
    hide(e) {
      if (e.target.className === 'contact') return
      this.isContactShow = false
    },
    handleContactImage(data) {
      switch (data.Name) {
        case 'Line@':
          return require('@/assets/images/common/line@.webp')
        case 'LiveChat':
          return require('@/assets/images/common/livechat.webp')
        case 'Zendesk':
          return require('@/assets/images/common/zendesk.webp')
        case 'Viber':
          return require('@/assets/images/common/Viber.webp')
        case 'Tawk':
          return require('@/assets/images/common/Tawk.webp')
        case 'JivoChat':
          return require('@/assets/images/common/jivochat.webp')
        case 'Telegram':
          return require('@/assets/images/common/tg.webp')
        case 'Custom':
          return data.ContactImage
      }
    },
    handleContact(data) {
      switch (data.Name) {
        case 'Line@':
        case 'Viber':
        case 'Tawk':
        case 'JivoChat':
        case 'Telegram':
        case 'Custom':
          window.open(data.ContactUrl || '')
          break
        case 'LiveChat':
          this.handleOpenLiveChat()
          break
        case 'Zendesk':
          this.$zendesk.show()
          this.$zendesk.zE('webWidget', 'open')
      }
      this.isContactShow = false
    }
  }
}

